#aiclo-testimonial-form, #aiclo-contact-form {
    .field-container {
        position: relative;
        margin-bottom: 20px;
    }

    .field-msg {
        display: none;
        font-size: 12px;
        //text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 0.05em;

        &.show {
            display: block;
        }
    }

    .error {
        color: rgb(144, 19, 19);
    }

    .success {
        color: rgb(47, 129, 47);
    }
}

#aiclo-subscribe-form {

    .field-msg {
        display: none;
        position: absolute;
        left: 2px;
        bottom: -14px;
        font-size: 9px;
        //text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 0.05em;

        &.show {
            display: block;
        }
    }

    .error {
        color: rgb(144, 19, 19);
    }

    .success {
        color: rgb(47, 129, 47);
    }
}